import React from 'react';

import { basePageWrap } from '../BasePage/index';
import s from './StandardPage.module.css';
import ContentBlocks from '../../components/blocks/ContentBlocks';
import { ContentBlocksProps } from '@/types/components/blocks';

const StandardPage = ({ content }: ContentBlocksProps) => {
    return (
        <>
            <div className={s['StandardPage']}>
                <ContentBlocks content={content} />
            </div>
        </>
    );
};

export default basePageWrap(StandardPage);
